import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2, H3, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../../shared/GatsbyButton';
import EqualHousingLogo from '../../../../shared/images/eho.png';

const Copy = styled(Paragraph)`
  margin: 0 0 30px;
`;

const EHOWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const EHO = styled.img`
  margin-left: 10px;
  width: 40px;
`;

// const CopySmallBreak = styled(Copy)`
//   margin-bottom: 20px;
// `;

const Title = styled(H2)`
  margin-bottom: 20px;
`;

const UL = styled.ul`
  padding-top: 30px;
  li {
    padding-bottom: 10px;
  }
`;

const TermsOfUse = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.LEGAL.DISCLOSURES} title="State licensing and disclosure information" description="Find out more about our disclosures and licensing. " />
    <UpdateAt date="May 2023" />
    <Container>
      <Title>State licensing and disclosure information.</Title>
      <EHOWrap>
        <H3>Beeline Loans, Inc. is an Equal Housing Lender</H3>
        <EHO src={EqualHousingLogo} alt="Equal Housing Lender" />
      </EHOWrap>
      <H3>Beeline Loans, Inc. is a licensed mortgage lender:</H3>
      <UL>
        <li>Alabama Consumer Credit License # 22925.</li>
        <li>Arizona Mortgage Banker License # 1018313.</li>
        <li>Arkansas Combination Mortgage Banker-Broker-Servicer License # 125249.</li>
        <li>California DFPI Financing Law License # 60DBO-146054 - Licensed by the Department of Financial Protection and Innovation under the California Finance Law License.</li>
        <li>
          Colorado Mortgage Company Registration - To check the license status of your mortgage loan originator, visit
          https://apps.colorado.gov/dre/licensing/Lookup/LicenseLookup.aspx;
        </li>
        <li>Delaware Lender License # 035661.</li>
        <li>District of Columbia Mortgage Lender License # MLB1799947.</li>
        <li>Florida Mortgage Lender License # MLD1810.</li>
        <li>Georgia Mortgage Lender License/Registration # 1799947.</li>
        <li>Illinois Residential Mortgage License # MB.6761603.</li>
        <li>Indiana-DFI Mortgage Lending License # 55606.</li>
        <li>Iowa Mortgage Banker License # 2021-0142.</li>
        <li>Kansas Mortgage Company License # MC.0025736.</li>
        <li>Kentucky Mortgage Company License # MC730987.</li>
        <li>Louisiana Residential Mortgage Lending License.</li>
        <li>Maryland Mortgage Lender License # 1799947.</li>
        <li>Massachusetts Mortgage Lender License # ML1799947.</li>
        <li>Michigan 1st Mortgage Broker/Lender License # FL0023226.</li>
        <li>Mississippi Mortgage Lender License # 1799947.</li>
        <li>North Carolina Mortgage Lender License # L-196415.</li>
        <li>Ohio Residential Mortgage Lending Act Certificate of Registration # RM.804731.000.</li>
        <li>Oklahoma Mortgage Lender License # ML014000.</li>
        <li>Pennsylvania Mortgage Lender License # 95422.</li>
        <li>Rhode Island Lender License # 20193876LL.</li>
        <li>South Carolina BFI Mortgage Lender/Servicer License # MLS-1799947.</li>
        <li>Tennessee Mortgage License # 235132.</li>
        <li>Texas SML Mortgage Company License.</li>
        <li>Virginia Lender License MC-6973.</li>
        <li>Wisconsin Mortgage Banker License # 1799947BA.</li>
      </UL>

      <Copy>
        Visit&nbsp;
        <ButtonText selected to="https://www.nmlsconsumeraccess.org" newWindow>
          NMLS consumer access page
        </ButtonText>{' '}
        for more information.
      </Copy>
      <Copy>
        Some products may not be available in all states. Information, rates and pricing are subject to change without prior notice at the sole discretion of Beeline Loans, Inc.
        All loan programs subject to borrowers meeting appropriate underwriting conditions. This is not a commitment to lend. Other restrictions apply. For questions regarding
        licensing, please contact us via phone{' '}
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.SALES}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.SALES}
        </ButtonText>{' '}
        or email us{' '}
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
        </ButtonText>
        .
      </Copy>
      <Copy>
        <strong>In Texas:</strong>
        <br />
        <br />
        CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A MORTGAGE BANKER OR A LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM
        TO THE TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE
        DEPARTMENT&apos;S WEBSITE AT WWW.SML.TEXAS.GOV. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT 1-877-276-5550. THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF
        CERTAIN ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS OF LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATORS. A WRITTEN APPLICATION FOR
        REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND,
        PLEASE CONSULT THE DEPARTMENT&apos;S WEBSITE AT{' '}
        <ButtonText selected to="www.sml.texas.gov" newWindow>
          WWW.SML.TEXAS.GOV
        </ButtonText>
        .
      </Copy>
      <Copy>
        <strong>
          STATE OF ILLINOIS
          <br />
          COMMUNITY REINVESTMENT NOTICE
        </strong>
        <br />
        <br /> The Department of Financial and Professional Regulation (Department) evaluates our performance in meeting the financial services needs of this community, including
        the needs of low-income to moderate-income households. The Department takes this evaluation into account when deciding on certain applications submitted by us for approval
        by the Department. Your involvement is encouraged. You may obtain a copy of our evaluation. You may also submit signed, written comments about our performance in meeting
        community financial services needs to the Department.
      </Copy>
      {/* <Copy>
        {CONST.ADDRESS}
        <br />
        NMLS #{CONST.LICENSING.NMLS}
        <br />
        {CONST.LICENSING.STATE_COPY}
      </Copy>

      <H3>Alabama</H3>
      <Copy>Mortgage Lender License Number: 22925.001</Copy>

      <H3>Arkansas</H3>
      <Copy>Mortgage Lender License Number: 125249</Copy>

      <H3>Arizona</H3>
      <Copy>Mortgage Lender License Number: 1018313</Copy>

      <H3>California</H3>
      <Copy>Mortgage Lender License Number: 60DBO-146054</Copy>

      <H3>Colorado</H3>
      <Copy>Mortgage Lender License Number: 1799947</Copy>

      <H3>Delaware</H3>
      <Copy>Lender License Number: 35661</Copy>

      <H3>District of Columbia</H3>
      <Copy>Mortgage Lender License Number: MLB1799947</Copy>

      <H3>Florida</H3>
      <Copy>Lender License Number: MLD1810</Copy>

      <H3>Georgia</H3>
      <Copy>Lender License Number: 1799947</Copy>

      <H3>Illinois</H3>
      <Copy>Lender License Number: 6761603</Copy>

      <H3>Indiana</H3>
      <Copy>Lender License Number: 55606</Copy>

      <H3>Iowa</H3>
      <Copy>Lender License Number: 2021-0142</Copy>

      <H3>Kansas</H3>
      <Copy>Lender License Number: MLB14001</Copy>

      <H3>Kentucky</H3>
      <Copy>Lender License Number: MC730987</Copy>

      <H3>Louisiana</H3>
      <Copy>Lender License Number: 1799947</Copy>

      <H3>Maryland</H3>
      <Copy>Lender License Number: 23972</Copy>

      <H3>Massachusetts</H3>
      <Copy>Lender License Number: ML 1799947</Copy>

      <H3>Michigan</H3>
      <Copy>Lender License Number: FL0023226</Copy>

      <H3>Mississippi</H3>
      <Copy>Lender License Number: 1799947</Copy>

      <H3>North Carolina</H3>
      <Copy>Lender License Number: L-196415</Copy>

      <H3>Rhode Island</H3>
      <Copy>Lender License Number: 20193876LL</Copy>

      <H3>Ohio</H3>
      <Copy>Lender License Number: RM.804731.000</Copy>

      <H3>Oklahoma</H3>
      <Copy>Lender License Number: ML014000</Copy>

      <H3>Pennsylvania</H3>
      <Copy>Lender License Number: 95422</Copy>

      <H3>South Carolina</H3>
      <Copy>Lender License Number: MLS-1799947</Copy>

      <H3>Tennessee</H3>
      <Copy>Lender License Number: 235132</Copy>

      <H3>Texas</H3>
      <CopySmallBreak>
        Mortgage Company License, <br />
        3403 Potomac Avenue, Suite 203, <br />
        Dallas, TX 75205
      </CopySmallBreak>

      <CopySmallBreak>
        Consumers wishing to file a complaint against a mortgage company or a licensed mortgage company residential mortgage loan originator should complete and send a complaint
        form to the Texas Department of Savings and Mortgage Lending, 2601 North Lamar, Suite 201, Austin, Texas 78705. Complaint forms and instructions may be obtained from the
        department’s website at www.sml.texas.gov. A toll-free consumer hotline is available at 1-877-276-5550.
      </CopySmallBreak>
      <Copy>
        The department maintains a recovery fund to make payments of certain actual out of pocket damages sustained by borrowers caused by acts of licensed mortgage company
        residential mortgage loan originators. A written application for reimbursement from the recovery fund must be filed with and investigated by the department prior to the
        payment of a claim. For more information about the recovery fund, please consult the department’s website at www.sml.texas.gov. The department maintains the mortgage broker
        recovery fund to make payments of certain actual out of pocket damages sustained by borrowers caused by acts of licensed residential mortgage loan originators. A written
        application for reimbursement from the recovery fund must be filed with and investigated by the department prior to the payment of a claim. For more information about the
        recovery fund, please consult Subchapter F of the Mortgage Broker License Act on the department’s web at www.sml.texas.gov.
      </Copy>

      <H3>Virginia</H3>
      <Copy>Lender License Number: MC-6973</Copy>

      <H3>Wisconsin</H3>
      <Copy>Lender License Number: 2044040BA</Copy>

      <H3>Equal Credit Opportunity Act.</H3>
      <Copy>
        As an equal housing lender, Beeline do not engage in business practices that discriminate on the basis of race, color, religion, national origin, sex, marital status, age
        (provided you have the capacity to enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you
        have, in good faith, exercised any right under the Consumer Credit Protection Act. Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.
      </Copy>

      <H3>More information.</H3>
      <Copy>
        For questions regarding licensing, please contact us via phone&nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.DOTS}
        </ButtonText>
        or email
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
        </ButtonText>
        <br />
        For more information visit the Nationwide Multistate Licensing System (NMLS)
        <ButtonText selected to="https://www.nmlsconsumeraccess.org" newWindow>
          www.nmlsconsumeraccess.org
        </ButtonText>
      </Copy> */}
    </Container>
  </NavOnlyTemplate>
);

export default TermsOfUse;
